import styled from "styled-components";
import {Link} from 'react-scroll'

export const Button = styled(Link)`

    border-radius: 50px;
    background: ${({primary}) => (primary ? '#FBBC14' : '#0db9a3')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    padding: ${({dark}) => (dark ? '#010606' : '$fff')};
    padding: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    transition: all 0.2s easy-in-out;

    &:hover{
        transition: all 0.2s easy-in-out;
        background: ${({primary}) => (primary ? '#0db9a3' : '#0D9BA3')};
    }

`
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FormContent, FormWrap, FormH1, BirdIcon, FormInputMSG, Form, FormLabel, FormInput, FormButton, Container } from "./ContactUsElements";
import bird from '../Images/bird.svg'

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2tpt8xh",
        "template_jz7y5it",
        form.current,
        "Jnu5lwT1TEsmj95QE"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
    
    <Container id="constactus">
        <FormWrap>
        <BirdIcon src={bird}/>
            <FormContent>
                <Form ref={form} onSubmit={sendEmail} >
                <FormH1>Let's Talk</FormH1>
                <FormLabel htmlFor='for'>Email</FormLabel>
                <FormInput type="email" name="user_email"></FormInput>
                <FormLabel htmlFor='for'>Message</FormLabel>
                <FormInputMSG name="message" required></FormInputMSG>
                <FormButton  type="submit" value="Send" >Submit</FormButton>
                </Form>
            </FormContent>
        </FormWrap>
    </Container>
    </>
  )};
  

export default ContactUs;
import React from "react";
import NavBar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import { useState } from "react";
import HeroSection from "../components/HeroSection/Index";
import InfoSection from "../components/InfoSection/Index";
import {homeObjOne, homeObjTwo, homeObjThree}  from "../components/InfoSection/Data";
import Services from "../components/Services/Index";
import Footer from "../components/Footer/Index";
import ContactUs from "../components/ContactUs/Index";

const Home = () =>{

    const [isOpen, setisOpen] = useState(false);

    const toggle = () =>{
        setisOpen(state => !state);
    }

    console.log(isOpen);

    return(
        <>
            <SideBar toggle={toggle} isOpen={isOpen}></SideBar>
            <NavBar toggle={toggle}></NavBar>
            <HeroSection />
            <InfoSection {...homeObjOne}/>
            <InfoSection {...homeObjTwo}/>
            <Services />
            <InfoSection {...homeObjThree}/>
            <ContactUs />
            <Footer/>
        </>
    );
}

export default Home;
import React from "react";
import Icon1 from '../Images/decide.svg';
import Icon2 from '../Images/progress.svg'
import Icon3 from '../Images/graduation.svg'
import { ServicesContainer, ServicesH1, ServicesH2, ServicesCard, ServicesP, ServicesIcon, ServicesWrapper } from "./ServicesElements";

const Services = () =>{

    return(
        <>
            <ServicesContainer id='services'>
                <ServicesH1>Our Services</ServicesH1>
                <ServicesWrapper>
                    <ServicesCard>
                        <ServicesIcon src={Icon1}/>
                        <ServicesH2>Reduce Expenses</ServicesH2>
                        <ServicesP>We Help Reduce your fees
                            and increase you revenue
                        </ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon2}/>
                        <ServicesH2>Virtual Offices</ServicesH2>
                        <ServicesP>We Help Reduce your fees
                            and increase you revenue
                        </ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon3}/>
                        <ServicesH2>Premium Benefits</ServicesH2>
                        <ServicesP>We Help Reduce your fees
                            and increase you revenue
                        </ServicesP>
                    </ServicesCard>
                </ServicesWrapper>
            </ServicesContainer>
        </>
    );
}

export default Services;
import React from "react";
import { FooterContainer, FooterInfo, ServicesIcon } from "./FooterElements";

const Footer = () =>{
    
    return(
        <>
        
            <FooterContainer>  
                <FooterInfo>© Orlin Harizanov. All Rights Reserved</FooterInfo>
            </FooterContainer>
            
        </>
    );
}

export default Footer;
import { hover } from "@testing-library/user-event/dist/hover";
import React, { useState } from "react";
import Video from '../../components/Videos/video.mp4'
import { Button } from "../ButtonElements.js";
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from "./HeroElements";

const HeroSection = () =>{

    const [hover, setHover] = useState(false);

    const onHover = () =>{
        setHover(!hover);
    }

    return(
        <>
            <HeroContainer id="home">
                <HeroBg>
                    <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
                </HeroBg>
                <HeroContent>
                    <HeroH1>Education Made Easy</HeroH1>
                    <HeroP> It must be remembered that the purpose of education is not to fill the minds 
                        of students with facts... it is to teach them to think.
                    </HeroP>
                        <HeroBtnWrapper>
                            <Button 
                            to="about" 
                            primary ="true"
                            onMouseEnter={onHover} 
                            onMouseLeave={onHover}
                            >
                                Get Started {hover ? <ArrowForward /> : <ArrowRight />}
                            </Button>
                        </HeroBtnWrapper>
                </HeroContent>
            </HeroContainer>
        </>
    );
}

export default HeroSection;
import styled from "styled-components";

export const FooterContainer=styled.div`
    display: flex;
    position: relative;
    background-color: #0d9ba3;
    justify-content: center;
    height: 40px;
    width: 100%;
`

export const FooterInfo=styled.div`
    background: #0D9BA3;
    display: flex;
    border: 10px black;
    align-items: center;
    color: #fff;
    font-size: 0.8rem;
    `
import styled from 'styled-components'
// import {Link as LinkR } from 'react-router-dom'
import {Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
    position : sticky;
    top :0;
    opacity: 10;
    background: #0D9BA3;
    height 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;clear
    
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
position : sticky;
top :0;

    display: flex;
    justify-content: space-between;
    
    height: 60px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled(LinkS)`
    color: #FBBC14;
    justify-self: flex-start;
    cursor: pointer;
    font-family: 'Love Ya Like A Sister', cursive;
    font-size: 2.9rem;
    display: flex;
    align-items: center;
    margin-left 5px;
    font-weight: bold;
    text-decoration: none;
`

export const MobileIcon = styled.div`
  display: none;
  

  @media screen and (max-width:768px){
    display: block;
    position absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff
    
  }
`

export const NavMenu = styled.ul`
    display: none;
    display: flex;
    align-item: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    
    @media screen and (max-width:768px){
        display: none;
      }
`

export const NavItem = styled.li`
    height: 60px;
    

`

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: 'Love Ya Like A Sister', cursive;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    

    &.active{
        color:  #fbbc14;
    }

`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width:768px){
        display: none;
      }

`

export const NavBtnLink = styled(LinkS)`
    border-radius: 50px;
    text-decoration: none;
    background: #FBBC14;
    white-space:nowrap;
    padding: 10px 22px;
    color: #010606;
    font-family: 'Love Ya Like A Sister', cursive;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: 010606;
    }

    @media screen and (max-width:768px){
        display: none;
      }

`

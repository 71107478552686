import React from "react";
import { SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, Icon, CloseIcon, SidebarWrapper, SidebarContainer } from "./SideBarElements";

const SideBar = ({isOpen, toggle}) =>{

    return(
        <>
            <SidebarContainer isOpen={isOpen}  >
        <Icon>
            <CloseIcon onClick={toggle}/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="about" onClick={toggle} >About</SidebarLink>
                <SidebarLink to="discover" onClick={toggle} >Discover</SidebarLink>
                <SidebarLink to="services" onClick={toggle} >Services</SidebarLink>
                <SidebarLink to="signup" onClick={toggle} >Sign up</SidebarLink>
                <SidebarLink to="constactus" onClick={toggle} >Contact Us</SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
        </>
    );
}

export default SideBar;

import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './pages';

function App() {
  return (
    <Home />
    
  );
}

export default App;

import graduation from '../Images/graduation.svg';
import friends from '../Images/friends.svg'
import education from '../Images/education.svg'
import decide from '../Images/decide.svg'
import progress from '../Images/progress.svg'

export const homeObjOne = {

    id: 'about',
    lightBg: false,
    darkText: false,
    lightText: false,
    lightTextDesc: false,
    topline: 'Premium Bank',
    headline: 'Unlimited Transaction with zero fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transaction',
    buttonLabel : 'Learn More',
    imgStart: false,
    img: graduation,
    alt: 'graduation',
    dark: true,
    primary: false
    
}

export const homeObjTwo = {

    id: 'discover',
    lightBg: true,
    darkText: true,
    lightText: false,
    lightTextDesc: false,
    topline: 'Premium Bank',
    headline: 'Unlimited Transaction with zero fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transaction',
    buttonLabel : 'Learn More',
    imgStart: true,
    img: decide,
    alt: 'decide',
    dark: true,
    primary: false
    
}

export const homeObjThree = {

    id: 'signup',
    lightBg: true,
    darkText: true,
    lightText: false,
    lightTextDesc: false,
    topline: 'Premium Bank',
    headline: 'Unlimited Transaction with zero fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transaction',
    buttonLabel : 'Learn More',
    imgStart: false,
    img: progress,
    alt: 'progress',
    dark: true,
    primary: false
    
}

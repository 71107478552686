import React from "react";
import { Button } from "../ButtonElements";
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, BtnWrap, ImgWrap, Img, TextWrapper, TopLine, Heading, Subtitle } from "./InfoElements";

const InfoSection = ({lightBg, id, imgStart, topline, lightText, to,
     headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2}) =>{

    return(
        <div>
          <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart ? 1 : 0} >
                        <Column1>
                        <TextWrapper>
                            <TopLine>{topline}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>

                                {/* <BtnWrap>
                                <Button 
                                    to='about' 
                                    smooth = {true}
                                    duration = {500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={primary ? 1 : 0}
                                    dark={dark ? 1 : 0}
                                    dark2={dark2 ? 1 : 0}
                                    >{buttonLabel}
                                </Button>
                                </BtnWrap> */}

                        </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt}/>
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </div>
    );
}

export default InfoSection;
import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'

export const HeroContainer = styled.div`
    background: 0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3px;
    height: 800px;
    position: relative;
    z-index: 1;
`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -0-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const HeroContent = styled.div`
   z-index:3;
   max-width: 1200px;
   position: absolute;
   padding: 8px 24px;
   display: flex;
   flex-direction: column;
   align-teims: center;
`
export const HeroH1 = styled.h1`
    background:transparent;
    padding:0.5rem 0.5rem;
    margin:0 0.5rem;
    font-size:2rem;

    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius:15px 255px;
    box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);
    border:solid 3px #FBBC14;
    color: #FBBC14;
    text-align: center;
    font-family: 'Love Ya Like A Sister', cursive;

    @media screen and (max-width 768px){
        font-size: 40px;
    }

    @media screen and (max-width 480px){
        font-size: 32px;
    }
`
export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width 768px){
        font-size: 24px;
    }

    @media screen and (max-width 480px){
        font-size: 18px;
    }
`
export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`
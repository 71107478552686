import React from "react";
import{ Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink} from "./NavBarElements"

import {FaBars} from 'react-icons/fa'
const NavBar = ({toggle}) =>{

    return(
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="home">Sunshine</NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-60}
                            >About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="discover"
                             smooth={true}
                             duration={500}
                             spy={true}
                             exact='true'
                             offset={-60}
                            >Discover</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="services"
                             smooth={true}
                             duration={500}
                             spy={true}
                             exact='true'
                             offset={-60}
                            >Services</NavLinks>
                        </NavItem>
                        <NavItem>
                        <NavLinks to="signup"
                             smooth={true}
                             duration={500}
                             spy={true}
                             exact='true'
                             offset={-60}
                            >Sign Up</NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to="constactus"
                             smooth={true}
                             duration={800}
                             spy={false}
                             exact='true'
                             offset={-150}
                        
                        
                        
                        >Contact Us</NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
    );
}

export default NavBar;